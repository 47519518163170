function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import sendMosStats from "@mos/eds-gif";
import { requestIdleCallback } from "./polyfills.js";
import { get as getCookie } from "browser-cookies";
import { sendProfileInfo } from "./sendProfileInfo.js";

// Подключает к странице счетчики Яндекс.Метрики и stats.mos.ru
// и предоставляет единый интерфейс сбора статистики.

if (!window.Spatz) {
  var DISABLE_YANDEX_METRIKA = false;
  var eventsQueue = [];
  var yaCountersInitConfigs = [{
    id: 32628510
  }, {
    id: 14112952
  }];
  var spatzYaCounters = [];
  var initYaCounter = function initYaCounter(id) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (window.ym === undefined || !id) {
      return;
    }
    window.ym(Number(id), "init", _objectSpread({
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: false,
      trackHash: true
    }, options));
    if (document.readyState !== "loading") {
      sendProfileInfo(id);
    } else {
      document.addEventListener("readystatechange", function () {
        sendProfileInfo(id);
      }, {
        once: true
      });
    }
    return id;
  };

  // Подключение скрипта Яндекс.Метрики и счетчиков 32628510 и 14112952
  var initYaMetrika = function initYaMetrika() {
    if (DISABLE_YANDEX_METRIKA) {
      return;
    }
    var init = function init() {
      yaCountersInitConfigs.forEach(function (config) {
        var counter = initYaCounter(config.id);
        if (!config.initOnly) {
          spatzYaCounters.push(counter);
        }
      });
      eventsQueue.forEach(function (event) {
        requestIdleCallback(function () {
          send(event, {
            mosStats: false
          });
        }, {
          timeout: 50
        });
      });
    };

    // Новый код инициализации Метрики, расшифрованный для понятности
    if (window.ym === undefined) {
      window.ym = function () {
        (window.ym.a = window.ym.a || []).push(arguments);
      };
    }
    window.ym.l = 1 * new Date();
    var scripts = document.getElementsByTagName("script");
    var src = "https://mc.yandex.ru/metrika/tag.js";
    if (document.querySelector("script[src=\"".concat(src, "\"]")) !== null) {
      init();
      return;
    }

    // Если скрипта нет, добавляем и стартуем счетчики
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = src;
    scripts[0].parentNode.insertBefore(script, scripts[0]);
    // Конец кода Метрики

    init();
  };
  var getStatsParams = function getStatsParams(event) {
    var eventSrc = window.location.href;
    if (event.src) {
      eventSrc = event.src;
    } else if (event.type) {
      eventSrc = "mos.ru";
    }
    return {
      eventType: event.type ? event.type : "mos-layout",
      eventSrc: eventSrc,
      eventDst: "stats",
      eventTime: Math.floor(Date.now() / 1000),
      mosId: getCookie("mos_id"),
      eventObject: event.type ? event.data || event.object : event
    };
  };

  /**
   * Отправляет событие статистики в разные системы.
   * В целом, может вызывать и другие методы Метрики, см. ниже
   *
   * @param {Object} event Объект события.
   *  Для stats.mos.ru нужно отправлять объект с полями type и data (см. функцию getStatsParams выше)
   *  Только для Метрики достаточно просто объекта свободной схемы.
   * @param [params = {}] Дополнительные параметры.
   * @param {string}  [params.method = 'params'] - метод отправки в Метрику:
   *  https://yandex.ru/support/metrica/objects/method-reference.html
   *  По умолчанию: "params".
   * @param {boolean} [params.yandex = true] - отправлять в Метрику или нет.
   * @param {boolean} [params.mosStats = true] - отправлять в stats.mos.ru или нет.
   * @param {...*} args Аргументы для вызова разных методов Метрики.
   *  Для стандартного params лучше обходиться без них.
   */
  var send = function send() {
    var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }
    var options = _objectSpread({
      yandex: true,
      mosStats: true,
      method: "params"
    }, params);
    if (options.method === "params" && (!event || _typeof(event) !== "object")) {
      return false;
    }
    if (options.yandex && !DISABLE_YANDEX_METRIKA && window.ym !== undefined) {
      if (spatzYaCounters.length) {
        spatzYaCounters.forEach(function (yaCounterId) {
          if (options.method === "params") {
            var _window;
            var data = event;
            if (event.type) {
              data = event.data || event.object;
            }
            (_window = window).ym.apply(_window, [yaCounterId, options.method, data].concat(args));
          } else {
            var _window2;
            (_window2 = window).ym.apply(_window2, [yaCounterId, options.method].concat(args));
          }
        });
      } else {
        eventsQueue.push(event);
      }
    }
    if (options.mosStats && options.method === "params") {
      sendMosStats(getStatsParams(event));
    }
  };

  /**
   * Добавить счетчик Метрики в список для отправки.
   * @param {number|string} counterId ID счетчика Метрики
   * @param {Object} [counterOptions] Параметры счетчика
   * @param {Object} [params] Настройки инициализации
   * @param {boolean} [params.initOnly] Только инициировать счетчик, но не отправлять в него события через Spatz.send
   */
  var addYaCounter = function addYaCounter(counterId, counterOptions) {
    var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var id = Number(counterId);
    if (!id || yaCountersInitConfigs.find(function (config) {
      return config.id === id;
    })) {
      return false;
    }
    var counter = initYaCounter(id, counterOptions);
    yaCountersInitConfigs.push({
      id: id,
      initOnly: params.initOnly
    });
    if (!params.initOnly && counter) {
      spatzYaCounters.push(counter);
    }
  };
  initYaMetrika();
  window.Spatz = {
    send: send,
    params: send,
    addYaCounter: addYaCounter
  };
}