export function requestIdleCallback(callback, options) {
  if (window.requestIdleCallback) {
    return window.requestIdleCallback(callback, options);
  }
  var start = Date.now();
  return window.setTimeout(callback, 1, {
    didTimeout: false,
    timeRemaining: function timeRemaining() {
      return Math.max(0, 50 - (Date.now() - start));
    }
  });
}
export function cancelIdleCallback(id) {
  if (window.cancelIdleCallback) {
    return cancelIdleCallback(id);
  }
  return window.clearTimeout(id);
}