function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/**
 * @fileOverview
 * Модуль для получения публичного идентификатора и отправки его в метрику.
 * Дополнительно отправляются кука mos_id, пол и возраст
 */
import { get as getCookie } from "browser-cookies";

/**
 * @typedef {object} ProfileUpdatedMessage
 * @property {'PROFILE_UPDATED'} type - Тип сообщения
 * @property {{public_id?: string}} profile - Упрощённая репрезентация профиля пользователя
 */

/**
 * @typedef {function} Subscription
 * @param {(ProfileUpdatedMessage | {type: string})[]} message - Сообщения от ACS
 * @returns {void}
 */

/**
 * @typedef {object} ACSMessageToCore
 * @property {'subscribe'|'unsubscribe'} type - Тип сообщения
 * @property {Subscription} subscription - Функция-подписка
 */

/**
 * @typedef {object} ACS
 * @description Упрощённый тип ACS
 * @property {(ACSMessageToCore) => void} send - Отправляет
 */

/**
 * Счётчики в которые мы отправляем идентификатор
 * @type {string[]}
 */
var counters = ["32628510", "61289299"];

/**
 * @type {Promise<ACS>}
 */
var acsPromise;

/**
 * Функция получения ACS
 * @returns {Promise<ACS>}
 */
function getACS() {
  // Если ACS уже на месте, сразу возвращаем
  if (window.core0521) {
    return Promise.resolve(window.core0521);
  }
  // Если скрипт ACS уже подключен, возвращаем ACS сразу, как только он загрузится.
  /**
   * @type {HTMLScriptElement | null}
   */
  var acsScript = document.querySelector('script[src*="core0521.js"], script[src*="core0521.dev.js"]');
  if (acsScript) {
    return new Promise(function (resolve) {
      acsScript.addEventListener("load", function () {
        resolve(window.core0521);
      });
    });
  }

  // Если не нашли ACS, следим за его добавлением
  return new Promise(function (resolve) {
    /** @type {MutationObserverInit} */
    var observerOptions = {
      subtree: false,
      attributes: false,
      characterData: false,
      childList: true
    };
    /**
     * @type MutationCallback
     */
    var observerCallback = function observerCallback(mutationsList, observer) {
      mutationsList.forEach(function (mutation) {
        if (mutation.type !== "childList" || mutation.addedNodes.length === 0) return;
        var acsScript = [].find.call(mutation.addedNodes, function (node) {
          return node.tagName === "SCRIPT" && /core0521/.test(node.src);
        });
        if (!acsScript) return;
        acsScript.addEventListener("load", function () {
          resolve(window.core0521);
        });
        observer.disconnect();
      });
    };
    var target = document.body;
    var observer = new MutationObserver(observerCallback);
    observer.observe(target, observerOptions);
  });
}

/**
 * Проверяем, отправляли ли мы текущий publicID в эту сессию.
 * Если, по каким-то причинам, сессионное хранилище недоступно - считаем что не отправляли.
 * @param {string} counterId
 * @param {string} publicId
 * @returns {boolean}
 */
function isPublicIdSent(counterId, publicId) {
  try {
    var sentPublicId = window.sessionStorage.getItem("spatz-pid-".concat(counterId));
    return sentPublicId === publicId;
  } catch (ignore) {
    return false;
  }
}

/**
 * Отправка параметров визита в Метрику
 * @param {string} counter
 * @param {Record<string, string>} params
 */
function sendParams(counter, params) {
  if (window.ym === undefined) return;
  window.ym(counter, "params", params);
}
export function sendProfileInfo(counterId) {
  if (!counters.includes(String(counterId))) return;
  if (!acsPromise) acsPromise = getACS();
  try {
    /**
     * @type {Subscription}
     */
    var subscription = function subscription(message) {
      /**
       * @type {ProfileUpdatedMessage | null}
       */
      var profileMessage = null;
      if (Array.isArray(message)) {
        var lastProfileMessage = message.reduce(function (_acc, msg) {
          if (msg.type === "PROFILE_UPDATED") return msg;
          return null;
        }, null);
        if (lastProfileMessage) {
          profileMessage = lastProfileMessage;
        }
      } else if (message.type === "PROFILE_UPDATED") {
        profileMessage = message;
      }
      if (!profileMessage || !profileMessage.profile.public_id) return;
      if (isPublicIdSent(counterId, profileMessage.profile.public_id)) return;
      try {
        var _profileMessage$profi = profileMessage.profile,
          public_id = _profileMessage$profi.public_id,
          gender = _profileMessage$profi.gender,
          birthdate = _profileMessage$profi.birthdate;
        var _birthdate$split = birthdate.split("-"),
          _birthdate$split2 = _slicedToArray(_birthdate$split, 2),
          year = _birthdate$split2[0],
          month = _birthdate$split2[1];
        var dateToSend = "".concat(year).concat(month);
        /**
         *
         * @type {object}
         * @property {string} sid - Значение параметра public_id из профиля
         * @property {string} mid - Значение куки mos_id
         * @property {"m" | "f"} s1 - Пол пользователя
         * @property {`${number}`} s2 - Год и месяц рождения пользователя в формате ГГГГММ
         */
        var params = {
          sid: public_id,
          mid: getCookie("mos_id"),
          s1: gender,
          s2: dateToSend
        };
        sendParams(counterId, params);
        window.sessionStorage.setItem("spatz-pid-".concat(counterId), profileMessage.profile.public_id);
      } catch (ignore) {
        /* ignore */
      }
    };
    acsPromise.then(function (acs) {
      return acs.send({
        type: "SUBSCRIBE",
        subscription: subscription
      });
    });
  } catch (ignore) {
    /* ignore */
  }
}